<template>
    <!-- 权益领取 -->
    <div class="container">
        <div class="container-content">
            <div class="container-content-home">
                <div class="content-home-bg">
                    <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/bg.png"/>
                </div>

                <div class="content-home-list">
                    <!-- 好礼N选二 -->
                    <div class="home-list-card">
                        <div class="home-list-title">
                            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/lingxing.png"/>
                            <span>好礼N选二</span>
                            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/lingxing.png"/>
                        </div>
                        <div class="home-list-item">
                            <div class="item-contain" v-for="item in exchangeList" v-if="item.item_name !== '5元话费券'">

                                <!-- 已领取 -->
                                <div class="item-received" v-if="isItemExchanged(item.item_name)">
                                  <img style="width: 80%;" src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/received.png"/>
                                </div>

                                <img class="item-bg" src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/item-bg.png"></img>
                                <div class="item-main" :class="{'opcity': exchangedCount() >= 2 }" @click="exchangedCount() >= 2 ? null :confirmClick(item)">
                                    <div class="item-main-icon">
                                        <img :src="item.item_icon">
                                        <div class="shadow" style="width: 60px;height: 20px;background-color: #f55;opacity: 0.15;filter: blur(10px);position: absolute;"></div>
                                    </div>
                                    <div class="item-main-text">
                                        <span class="itemName">{{ item.item_name }}</span>
                                        <span class="itemName">{{ item.item_note }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- 话费券 -->
                    <div class="home-list-card" style="padding-bottom: 20px;" v-for="item in exchangeList" v-if="item.item_name == '5元话费券'">
                        <div class="home-list-title">
                            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/lingxing.png"/>
                            <span>话费券</span>
                            <img src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/lingxing.png"/>
                        </div>

                        <div class="home-list-quan">
                            <div class="quan-item">
                                <img class="item-bg" style="width: 100%;" src="https://cdn.qiumo.net.cn/allsmarketmaterial/lky_gzh/quan_bg.png"></img>
                                <div class="item-main">
                                    <div class="main-leftWrap">
                                        <img :src="item.item_icon">
                                        <div class="left-text">
                                            <span class="title">{{ item.item_name }}</span>
                                            <span class="info">领取起30天内有效，单笔支付金额大于或等于5.01元可用</span>
                                        </div>
                                    </div>
                                    <div class="main-rightWrap">
                                        <button v-if="isItemExchanged(item.item_name)" class="unReceived">已领取</button>
                                        <button v-else class="received" @click="finalSubmit2(item.item_name)">领取</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="showModal" class="modal-container" @click="handleOutsideClick($event)">
          <div class="modal-content">
            <div class="modal-title">权益领取</div>
            <div class="modal-item">
              <span>所选权益</span>
              <span>{{current_item}}</span>
            </div>

            <div class="modal-slice"></div>

            <div class="modal-item">
              <span>领取号码</span>
              <span>{{user_telephone}}</span>
            </div>
            <button @click="finalSubmit()">立即领取</button>
          </div>
        </div>



        <v-alert
          :visibility.sync="alert.visibility"
          :title="alert.title"
          :message="alert.message"
          :rulestype="alert.rulestype"
          @handleSubmit="handleSubmit"
        />

    </div>
  </template>
  
  <script>
  import VAlert from "./record-alert/alert.vue";
  import { mapMutations, mapGetters, mapActions } from "vuex";
  import { equityList,equityReceive } from "@/api/lky_gzh/index.js";
  
  export default {
    components: {
      VAlert
    },
    data() {
      return {
        alert: {
          visibility: false,
          title: "",
          message: "",
        },
        showModal: false,
        current_item: "",
        user_telephone: [],

        vipType:"",
        state: false,
        exchangeList:[],
        list: [],
        title: "",
        appName: "",
        useRules:{},
        row:{},

        exchangeStatus:false,
        buyItemStatusTip:'',
      };
    },
    created() {
      console.log(this.$route.query.type);
      this.vipType = this.$route.query.type

      this["lky/getUserInfo"]();
      this.getList();
      this.exchangedStatus(this.vipType)
    },
    computed: {
      ...mapGetters(["lky_token", "lky_user_info"]),
    },
    methods: {
      ...mapActions(["lky/getUserInfo"]),
      ...mapMutations(["lky/SET_TOKEN"]),

      async getList() {
        this.$toast.loading({
            duration: 0,
            forbidClick: true,
            message: "加载中...",
          });
        const res = await equityList({ item_require: this.vipType });
        console.log(res)
        if (res.code == 0) {
          this.exchangeList = res.item;
          this.$toast.clear();
        }else{
          
        }
      },
      exchangedStatus(type) {
        console.log("type = ",type)
          const data = this.lky_user_info.exchanged_items[type] || [];

          console.log("data = ",data)

          this.user_telephone = this.lky_user_info.phone_number
          // if(data.includes('5元话费券')){
          //   return data.length >= 3;
          // }else{
          //   return data.length >= 2;
          // }
      },
      isItemExchanged(itemName){
        const data = this.lky_user_info.exchanged_items[this.vipType] || [];
        return data.includes(itemName);
      },

      exchangedCount() {
          const data = this.lky_user_info.exchanged_items[this.vipType] || [];
          // 过滤掉名称为 "5元话费券" 的项
          const filteredData = data.filter(item => item !== "5元话费券");
          return filteredData.length;
      },

      confirmClick(item){
        console.log("1111111111111",this.lky_user_info.exchanged_items[this.vipType])


        console.log("item = ",item)
        this.showModal = true;
        this.current_item = item.item_name
      },

      handleOutsideClick(event) {
        if (!event.target.closest(".modal-content")) {
          this.showModal = false;
        }
      },

      // 领取
      async finalSubmit() {
        this.$toast.loading({
            duration: 0,
            forbidClick: true,
            message: "加载中...",
          });
          await equityReceive(this.lky_token,{ 'item_name': this.current_item, item_require: this.vipType })
          .then(res =>{
            if (res.code == 0) {
                this.buyItemStatusTip = '领取成功';
                this.exchangeStatus = true;
                this.alert = {
                  visibility: true,
                  message: res.message,
                  title: this.buyItemStatusTip,
                };
                this.$toast.clear();

                if (!this.lky_user_info.exchanged_items[this.vipType]) {
                  // 如果为 undefined，则初始化为空数组
                  this.lky_user_info.exchanged_items[this.vipType] = [];
                }
                // 确保已经是数组后再调用 push
                this.lky_user_info.exchanged_items[this.vipType].push(this.current_item);
            } else {
                this.buyItemStatusTip = '领取失败';
                this.alert = {
                  visibility: true,
                  message: res.message,
                  title: this.buyItemStatusTip,
                };
                this.$toast.clear();
            }
          }).catch(err =>{
            this.$toast.clear();
          })
      },
      // 领取话费券
      async finalSubmit2(name) {
        this.current_item = name
        this.$toast.loading({
            duration: 0,
            forbidClick: true,
            message: "加载中...",
          });
          await equityReceive(this.lky_token,{ 'item_name': this.current_item, item_require: this.vipType })
          .then(res =>{
            if (res.code == 0) {
                if (!this.lky_user_info.exchanged_items[this.vipType]) {
                  // 如果为 undefined，则初始化为空数组
                  this.lky_user_info.exchanged_items[this.vipType] = [];
                }
                // 确保已经是数组后再调用 push
                this.lky_user_info.exchanged_items[this.vipType].push(this.current_item);

                this.buyItemStatusTip = '领取成功';
                this.exchangeStatus = true;
                this.alert = {
                  visibility: true,
                  message: res.message,
                  title: this.buyItemStatusTip,
                };
                this.$toast.clear();
            } else {
                this.buyItemStatusTip = '领取失败';
                this.alert = {
                  visibility: true,
                  message: res.message,
                  title: this.buyItemStatusTip,
                };
                this.$toast.clear();
            }
          }).catch(err =>{
            this.$toast.clear();
          })
      },
      handleSubmit(){
        this.visibility = false;
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #F7F7F7;
    .container-content{
      width: 100%;
      height: calc(100% - 110px);
      .container-content-home{
        background-color: #F7F7F7;
        width: 100%;
        height: 100%;
        position: relative;
        .content-home-list{
            position: absolute;
            left: 0;
            right: 0;
            top: 250px;

            .home-list-card{
                border-radius: 13.953px;
                background: #FFF;
                box-shadow: 0px 1.744px 3.488px 0px rgba(0, 0, 0, 0.15);
                margin: 0 20px 30px 20px;
            }
        }
        .content-home-bg{
          width: 100%;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .home-list-title{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    span{
        color: #BB7000;
        font-size: 27.907px;
        margin: 0 10px;
    }
    img{
        width: 21px;
    }
  }


  .home-list-item{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
    .item-contain{
        position: relative;
        width: 32%;
        margin-top: 12px;
    }
    .item-bg{
        width: 210px;
        height: 288px;
    }
    .item-main{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 12%;
        left: -7%;
        width: 100%;

        .item-main-icon img{
            width: 118px;
            height: 118px;
        }
        .item-main-text{
            display: flex;
            flex-direction: column;
            margin-top: 20px;
        }
        .item-main-text span{
            color: #F55;
            text-align: center;
            font-size: 26px;
            flex-wrap: wrap;
        }
    }
  }

  .home-list-quan{
    margin: 20px 20px;
  }

  .quan-item{
    position: relative;
    .item-main{
        position: absolute;
        top: 50%;
        right: 30px;
        transform: translate(0,-50%);
        display: flex;
        justify-content: space-between;

        .main-leftWrap{
            width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;

            .left-text{
                width: 70%;
                display: flex;
                flex-direction: column;
                .title{
                    color: #F55;
                    font-size: 36px;
                    padding: 5px 0;
                }
                .info{
                    color: #BBB;
                    font-size: 22px;
                }
            }
        }

        .main-rightWrap{
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .main-rightWrap .received{
            width: 100%;
            border-radius: 24px;
            border: 1px solid #F55;
            background: rgba(255, 112, 112, 0.50);
            color: #fff;
            font-size: 28px;
        }

        .main-rightWrap .unReceived{
            width: 100%;
            border-radius: 24px;
            border: 1px solid #B1B1B1;
            background: rgba(177, 177, 177, 0.50);
            color: #fff;
            font-size: 28px;
        }

        .main-leftWrap img{
            width: 100px;
            // height: 90px;
            margin-left: 10px;
            margin-right: 20px;
        }
    }
  }


  .item-received{
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    width: 92%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  /* 底部领取权益弹窗begin */
	.modal-container {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 999;
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	.modal-content {
		background-color: #fff;
		padding: 20px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		width: 100%;
		height: 40%;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.modal-content img {
		position: absolute;
		right: 2%;
		top: 5%;
		width: 6%;
	}

	.modal-content .modal-title {
		height: 48px;
		font-weight: 600;
		font-size: 32px;
		color: #333333;
		line-height: 48px;
		margin: 30px 0 15px 0;
	}

	.modal-content .modal-item {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.modal-content .modal-item span:nth-child(1) {
		height: 42px;
		font-weight: 400;
		font-size: 28px;
		color: #888888;
		line-height: 42px;
	}

	.modal-content .modal-item span:nth-child(2) {
		height: 42px;
		font-weight: 400;
		font-size: 28px;
		color: #333333;
		line-height: 42px;
	}

	.modal-content .modal-slice {
		width: 100%;
		height: 1px;
		background-color: #e3e3e3;
		margin: 20px 0;
	}

	.modal-content button {
		width: 70%;
		height: 15%;
		background: linear-gradient(335deg, #FF7C00 0%, #FF5000 100%);
		border-radius: 100px;
		border: none;
		font-size: 32px;
		color: #FFFFFF;
		position: absolute;
		bottom: 0;
		margin: 0 0 50px 0;
	}
	/* 底部领取权益弹窗end */

  .opcity{
    opacity: 0.4;
  }
  </style>