<template>
  <van-popup
    :close-on-click-overlay="false"
    v-model="visibility"
    class="dialog-alert">
    <div class="title">{{ title }}</div>
    <div class="cont">{{ message }}</div>
    <div class="footer" v-if="rulestype == 1">
      <div class="submit" @click="close">我知道了</div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "使用说明",
    },
    message: { type: String, default: "提示语 " },
    rulestype:{
      type: Number,
      default: 1,
    }
  },
  methods: {
    close() {
      this.$emit("update:visibility", false);
    },
    handleSubmit() {
      this.$parent.handleSubmit();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-alert {
  border-radius: 18px;
  width: 658px;
  padding-bottom: 42px;

  .title {
    font-weight: 600;
    font-size: 32px;
    color: #333333;
    line-height: 45px;
    text-align: center;
    padding: 40px 0 28px 0;
  }

  .cont {
    padding: 0 55px;
    font-weight: 400;
    font-size: 28px;
    color: #333333;
    line-height: 40px;
    max-height: 600px;
    overflow-y: auto;
    white-space: pre-wrap;
    text-align: center;
  }
  .footer {
    display: block;
    text-align: center;
    width: 480px;
    height: 88px;
    line-height: 88px;
    color: #fff;
    font-size: 32px;
    text-align: center;
    border-radius: 80px;
    margin: 64px auto 0;
    background: linear-gradient(335deg, #FF7C00 0%, #FF5000 100%);
    box-shadow: 0 14px 15px rgba(78, 169, 253, 0.3);
  }
}
</style>
